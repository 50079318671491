export default [
  {
    header: 'Hồ sơ',
    action: 'read',
    resource: 'ContractsTitle',
  },
  {
    title: 'Danh sách',
    route: 'contracts-list',
    icon: 'ListIcon',
    action: 'read',
    resource: 'Contracts',
  },
  {
    title: 'Hồ sơ mở',
    route: 'contracts-open',
    icon: 'RefreshCcwIcon',
    action: 'read',
    resource: 'ContractsOpen',
  },
  {
    title: 'Hồ sơ trả',
    route: 'contracts-verify-deny',
    icon: 'XCircleIcon',
    action: 'read',
    resource: 'ContractsVerifyDeny',
  },
  {
    title: 'Tạo mới',
    route: 'contracts-create',
    icon: 'FilePlusIcon',
    action: 'create',
    resource: 'Contract',
  },
]
